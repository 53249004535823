import React from 'react'
import { makeStyles, fade } from '@material-ui/core'
import { StaticQuery, graphql } from 'gatsby'
import VideoBg from "reactjs-videobg";
import mp4 from '../../../static/video/owner-video.mp4'
import clsx from 'clsx'
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
    root: {        
        flexGrow: 1,
        minWidth: '100vw',
        width: 'auto',        
        zIndex: 4,
        minHeight: '100vh',

        [theme.breakpoints.only('xs')]: {
            top: 0
        }
    },
    rootVideo: {
        backgroundColor: fade('#000', .87),
        position: 'absolute !important'
    }
}))

const OwnerHeroBg = ({ children, className, ...props }) => {
    const classes = useStyles()

    return (
        <StaticQuery
            query={graphql`
                query {
                    file(relativePath: { eq: "owner-hero.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }                    
                }
            `}
            render={data => {
                return <VideoBg
                    wrapperClass={classes.rootVideo}
                    poster={<Img
                        fluid={data.file.childImageSharp.fluid}
                        className={clsx(classes.root, className)}
                        style={{ position: "absolute" }}
                    />}
                >
                    <VideoBg.Source src={mp4} type="video/mp4" />
                </VideoBg>;

                //   
            }}
        />
    )
}

export default OwnerHeroBg