import React from 'react'
import OwnerHeroBg from '../owner/OwnerHeroBg'
import { Button, Box, Container, makeStyles, fade } from '@material-ui/core'
import CardWithOverhangButton from '../CardWithOverhangButton'

const useStyles = makeStyles(theme => ({
    content: {
        zIndex: 5
    },
    card: {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: fade(theme.palette.common.black, .6),
        boxShadow: theme.shadows[16],
        //color: `rgba(0, 0, 0, 0.87)` // text primary from light theme
    }
}))


const OwnerHeroSection = ({ title, paragraph, buttonLabel }) => {
    const classes = useStyles()

    return (
        <Box
            minHeight="100vh"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            overflow="hidden"
            position="relative"
        >
            <OwnerHeroBg />

            <Container maxWidth="md" className={classes.content}>
                <CardWithOverhangButton
                    title={title}
                    titleColor="primary"
                    paragraph={paragraph}
                    buttonSlot={<Button variant="contained" color="primary">{buttonLabel}</Button>}
                    className={classes.card}
                />
            </Container>

        </Box>
    )
}

export default OwnerHeroSection
