import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Grid, Container } from '@material-ui/core'
import Text from '../Text'
import { useMobile } from '../../utils/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const useStyles = makeStyles(theme => ({
    list: {
        listStyle: 'none',
        [theme.breakpoints.up('xs')]: {
            padding: 0
        }
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start'
    },
    dotWrapper: {
        display: 'inline-block',
        marginRight: 7,
    },
    dot: {
        verticalAlign: 'middle',
        marginTop: 2,
        fontSize: '.85em'
    }
}))



const OwnerInfoSection = ({ titleColor, dotColor, color, items, addonText }) => {
    const classes = useStyles()
    const mobile = useMobile()

    const columns = mobile ? [items] : [items.slice(0, items.length / 2), items.slice(items.length / 2)]

    return (<>
        <Box id="info" py={mobile ? 4 : 8} color={color} bgcolor="rgba(0,0,0,.82)">
            <Container>
                <Text variant="h2" className={classes.header} mb={4} color={titleColor} textAlign="center" data-sal="fade" data-sal-duration="500" data-sal-delay="200">
                    À propos du marché financier aujourd'hui
                </Text>

                <Box mt={4} data-sal="fade" data-sal-duration="1000" data-sal-delay="400">
                    <Grid container className={classes.list} spacing={4}>
                        {columns.map(col => <Grid item container xs={12} sm={6} spacing={2}>
                            {col.map(item =><Grid item className={classes.listItem}>
                                <Box color={dotColor} className={classes.dotWrapper}>
                                <FontAwesomeIcon icon="circle" fontSize="inherit" className={classes.dot} />
                                </Box>

                                <Text variant="body1" style={{ display: 'inline' }}>
                                    {item}
                                </Text>
                            </Grid>)}
                        </Grid>)}
                    </Grid>
                </Box>
            </Container>
        </Box>
        <Box bgcolor={"rgba(0, 0, 0, 0.87)"} py={7}>
            <Container>
                <Box textAlign="center">
                    <Text variant="h4" fontWeight={400} component="span" data-sal="fade" data-sal-duration="500" data-sal-delay="400">
                        {addonText}
                    </Text>
                </Box>
            </Container>
        </Box>
        </>
    )
}

OwnerInfoSection.defaultProps = {
    dotColor: 'primary.main',
    titleColor: 'primary.mainGradient'
}

export default OwnerInfoSection
