import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import OwnerInfoSection from '../components/owner/OwnerInfoSection'
import Contact from '../components/Contact'
import OwnerHeroSection from '../components/owner/OwnerHeroSection'
import OwnerContactBg from '../components/owner/OwnerContactBg'
import { Button } from '@material-ui/core'

const heroTitle = "Voulez-vous que votre activité retrouve sa splendeur?"

const heroParagraph = <>...du fond de <b>la crise</b>,  quand la menace, ils sont morts tous et reste <b>dégueulasse!</b></>

const infoList = [
  <><b>Le 2026</b> la <b>moitié</b> des participants aux <b>grandes fortunes françaises</b> seront une <b>génération millenials</b></>,
  <><b>Le 2036</b> les <b>jeunes</b> générations constitueront déjà <b>plus de 60%</b> des millionnaires</>,
  <>approfondissement la <b>numérisation de la population</b></>,
  <><b>traditionnelles</b> peinent à rattraper un <b>retard technique</b> et n’est pas compensé par la qualité du conseil et des opportunités proposées</>,
  <><b>taux</b> toujours plus bas, vu comme <b>négatif</b></>,
  <>commoditisation des services de gestion de portefeuille</>,
  <><b>disparition des actifs sans risques</b></>,
  <>la conscience des investisseurs à privilégier des <b>opportunités d’investissement en ligne</b> avec leur temps et dont l’impact est positif</>
]

const infoAddon = <>
Grâce à <b>incubit restart</b>, vous pouvez <b>soutenir</b> votre <b>café, restaurant,<br/> brasserie ou coiffeur / salon de beauté préféré</b> et participer directement <br/> dans la <b> “financement en échange de royalties”</b> (Revenue Based Finance)
</>

const renderSubmit = () => <Button variant="contained" color="primary" type={'submit'}>
  Register now
</Button>

const OwnerPage = props => {
  return (
    <Layout>
      <Seo title="Incubit - Owner" />

      <OwnerHeroSection
        title={heroTitle}
        paragraph={heroParagraph}
        buttonLabel="S'INSCRIRE POUR BETA"
      />

      <OwnerInfoSection items={infoList} addonText={infoAddon} />

      <Contact formName="owner" renderSubmit={renderSubmit} bgComponent={OwnerContactBg} />
    </Layout>
  )
}

export default OwnerPage
