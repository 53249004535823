import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { StaticQuery, graphql } from 'gatsby'
import clsx from 'clsx'
import Img from "gatsby-image"

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        minWidth: '100vw',
        height: '100%', 
        zIndex: 4,

        [theme.breakpoints.only('xs')]: {
            top: 0
        }
    }
}))

const OwnerContactBg = ({ children, className, ...props }) => {
    const classes = useStyles()

    return (        
        <StaticQuery
            query={graphql`
                query {
                    file(relativePath: { eq: "owner-contact.png" }) {
                        childImageSharp {
                            fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }                    
                }
            `}
            render={data => {
                return <Img fluid={data.file.childImageSharp.fluid} className={clsx(classes.root, className)} style={{position: "absolute"}} />
            }}
        />
    )
}

export default OwnerContactBg